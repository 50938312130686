<template>
    <!-- HTML : 1.1.0 -->
    <GlobalPopupGlobal></GlobalPopupGlobal>
    <NuxtLoadingIndicator :color="'#FF8050'" />
    <ButtonBackToTop></ButtonBackToTop>
    <GlobalHeader></GlobalHeader>
    <NuxtPage></NuxtPage>
    <GlobalFooter></GlobalFooter>
    <VEditor />
</template>
<script lang="ts" setup>
useDirectusGlobalSeo()
</script>
